var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"horizontal-layout",class:[_vm.layoutClasses],staticStyle:{"height":"inherit"},attrs:{"data-col":_vm.isNavMenuHidden ? '1-column' : null}},[_c('b-navbar',{staticClass:"header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed",class:{ 'fixed-top': _vm.$store.getters['app/currentBreakPoint'] !== 'xl' },style:({
    backgroundColor:
      _vm.navbarType === 'static' && _vm.scrolledTo && _vm.skin === 'light'
        ? 'white'
        : null,
    boxShadow:
      _vm.navbarType === 'static' && _vm.scrolledTo
        ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px'
        : null,
  }),attrs:{"toggleable":false}},[_vm._t("navbar",function(){return [_c('app-navbar-horizontal-layout-brand'),_c('app-navbar-horizontal-layout',{attrs:{"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive}})]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive})],2),_c('div',{staticClass:"horizontal-menu-wrapper"},[(!_vm.isNavMenuHidden)?_c('div',{staticClass:"header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border d-none d-xl-block",class:[_vm.navbarMenuTypeClass]},[_c('horizontal-nav-menu')],1):_vm._e(),_c('vertical-nav-menu',{staticClass:"d-block d-xl-none",attrs:{"is-vertical-menu-active":_vm.isVerticalMenuActive,"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_vm._t("vertical-menu-header",null,null,slotProps)]}}],null,true)})],1),_c('div',{staticClass:"sidenav-overlay",class:_vm.overlayClasses,on:{"click":function($event){_vm.isVerticalMenuActive = false}}}),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c(_vm.layoutContentRenderer,{key:_vm.layoutContentRenderer === 'layout-content-renderer-left'
        ? _vm.$route.meta.navActiveLink || _vm.$route.name
        : null,tag:"component",scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)})],1),_c('footer',{staticClass:"footer footer-light",class:[_vm.footerTypeClass]},[_vm._t("footer")],2),_vm._t("customizer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }